import { zodResolver } from '@hookform/resolvers/zod'
import { Heading } from 'components/heading'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/Input'
import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet'
import { toastConfig } from 'config/toast.config'
import { CreateProjectSchema, CreateProjectSchemaType, ProjectStatus } from 'modules/project/schemas/project.schema'
import { useProjectStore } from 'modules/project/store/project.store'
import { forwardRef, memo, useCallback, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { useCreateProjectMutation } from 'redux-store/api/project-api'
import { formatUrl } from 'utils/format-url'

interface CreateProjectSheetProps extends React.ComponentProps<typeof SheetTrigger> {
    className?: string
}
export const CreateProjectSheet = memo(
    forwardRef<React.ComponentRef<typeof SheetTrigger>, CreateProjectSheetProps>(({ ...props }, ref) => {
        const auth = useAuthUser()()
        const [disabled, setDisabled] = useState<boolean>(false)

        const isCreateProjectOpen = useProjectStore(state => state.isCreateProjectOpen)
        const setCreateProjectOpen = useProjectStore(state => state.setCreateProjectOpen)
        const openSnippetsModal = useProjectStore(state => state.openSnippetsModal)
        const [createProject, createProjectRes] = useCreateProjectMutation()

        const projectForm = useForm<CreateProjectSchemaType>({
            resolver: zodResolver(CreateProjectSchema),
            defaultValues: {
                name: '',
                url: '',
            },
        })

        const onCreateSubmit = useCallback<(values: CreateProjectSchemaType) => Promise<void>>(
            async values => {
                try {
                    setDisabled(true)
                    const url = new URL(values.url)
                    const baseUrl = `${url.protocol}//${url.hostname}${url.hostname === 'localhost' ? `:${url.port}` : ''}`

                    const res = await createProject({
                        name: values.name,
                        url: formatUrl(baseUrl),
                        status: ProjectStatus.PENDING,
                        ownerId: auth?.userOwnerId === 'owner' ? auth?.id : auth?.userOwnerId,
                    }).unwrap()
                    if (createProjectRes.isError) {
                        console.error(createProjectRes.error)
                        throw new Error()
                    }
                    if (!res || !res.id) {
                        throw new Error('No project data (ID) in response')
                    }

                    toastConfig.project.create.success(values.name)
                    projectForm.reset()
                    setCreateProjectOpen(false)
                    openSnippetsModal({
                        id: res.id,
                        name: values.name,
                    })
                } catch (error) {
                    console.error(error)
                    toastConfig.project.create.error(values.name)
                } finally {
                    setDisabled(false)
                }
            },
            [
                auth?.id,
                auth?.userOwnerId,
                createProject,
                createProjectRes.error,
                createProjectRes.isError,
                openSnippetsModal,
                projectForm,
                setCreateProjectOpen,
            ],
        )

        return (
            <Sheet open={isCreateProjectOpen} onOpenChange={setCreateProjectOpen}>
                <SheetTrigger ref={ref} {...props} />
                <SheetContent>
                    <Heading title="Create new project" description="And start collecting statistics" className="my-5" />
                    <Form {...projectForm}>
                        <form onSubmit={projectForm.handleSubmit(onCreateSubmit)} className="space-y-3">
                            <FormField
                                control={projectForm.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                type="text"
                                                name="name"
                                                placeholder="Project Name"
                                                required
                                                disabled={disabled}
                                                className="w-full"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={projectForm.control}
                                name="url"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>URL</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                type="text"
                                                name="url"
                                                placeholder="https://visitor-activity.com"
                                                required
                                                disabled={disabled}
                                                className="w-full"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Button type="submit" disabled={disabled} className="w-full">
                                Create Project
                            </Button>
                        </form>
                    </Form>
                </SheetContent>
            </Sheet>
        )
    }),
)
CreateProjectSheet.displayName = 'CreateProjectSheet'
