import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { ProjectCodeSnippets } from 'modules/project/components/project-code-snippets'
import { useProjectStore } from 'modules/project/store/project.store'
import { forwardRef, memo } from 'react'
import { cn } from 'utils/cn'

interface SnippetsModalProps extends React.ComponentProps<typeof DialogContent> {}
export const SnippetsModal = memo(
    forwardRef<React.ComponentRef<typeof DialogContent>, SnippetsModalProps>(({ className, ...props }, ref) => {
        const open = useProjectStore(state => state.isSnippetsModalOpen)
        const id = useProjectStore(state => state.id)
        const name = useProjectStore(state => state.name)
        const closeSnippetsModal = useProjectStore(state => state.closeSnippetsModal)

        return (
            <Dialog open={open} onOpenChange={closeSnippetsModal}>
                <DialogContent ref={ref} {...props} className={cn('max-w-5xl overflow-y-scroll', className)}>
                    <DialogHeader>
                        <DialogTitle>Code snippets for project "{name}"</DialogTitle>
                        <DialogDescription>
                            Copy and paste necessary code snippets into your project's source code
                        </DialogDescription>
                    </DialogHeader>
                    <ProjectCodeSnippets
                        projectId={id}
                        blockSnippets={false}
                        className="mt-5 flex max-h-0 min-h-[20vh] w-full max-w-[60.8em] flex-col gap-6"
                    >
                        <div className="pb-1" />
                    </ProjectCodeSnippets>
                </DialogContent>
            </Dialog>
        )
    }),
)
SnippetsModal.displayName = 'SnippetsModal'
