import { BarChart } from 'components/bar-chart'
import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { Card, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { addDays, format, isSameDay, startOfWeek } from 'date-fns'
import dayjs from 'dayjs'
import { CalendarIcon } from 'lucide-react'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { forwardRef, memo, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { cn } from 'utils/cn'

interface DetailsUsersChartProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsUsersChart = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsUsersChartProps>(({ data, ...props }, ref) => {
        const [date, setDate] = useState<DateRange | undefined>({
            from: startOfWeek(new Date(), { weekStartsOn: 1 }),
            to: startOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 }),
        })

        const chartData = useMemo(() => {
            if (!date || !date.from || !date.to) return []

            const modifiedData = data.filter(
                item => dayjs(item.date).isAfter(date.from) && dayjs(item.date).isBefore(date.to, 'day'),
            )

            const daysInRange = []
            let currentDate = dayjs(date.from)
            while (currentDate.isBefore(date.to, 'day') || currentDate.isSame(date.to, 'day')) {
                daysInRange.push(currentDate)
                currentDate = currentDate.add(1, 'day')
            }

            return daysInRange.map(day => ({
                name: day.format('DD'),
                value: modifiedData.filter(item => isSameDay(day.toDate(), new Date(item.date))).length,
            }))
        }, [data, date])

        return (
            <Card ref={ref} {...props}>
                <CardHeader className="flex flex-col lg:flex-row lg:items-center justify-between">
                    <div>
                        <CardTitle>Overview</CardTitle>
                        <CardDescription>Active users for selected range</CardDescription>
                    </div>
                    <div className={cn('grid gap-2')}>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    id="date"
                                    variant={'outline'}
                                    className={cn(
                                        'w-[300px] justify-start text-left font-normal',
                                        !date && 'text-muted-foreground',
                                    )}
                                >
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {date?.from ? (
                                        date.to ? (
                                            <>
                                                {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                                            </>
                                        ) : (
                                            format(date.from, 'LLL dd, y')
                                        )
                                    ) : (
                                        <span>Pick a date</span>
                                    )}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                                <Calendar
                                    initialFocus
                                    mode="range"
                                    defaultMonth={date?.from}
                                    selected={date}
                                    onSelect={setDate}
                                    numberOfMonths={2}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                </CardHeader>
                <BarChart data={chartData} height={350} />
            </Card>
        )
    }),
)
DetailsUsersChart.displayName = 'DetailsUsersChart'
