import { CommandMenu } from 'components/command-menu'
import { Button } from 'components/ui/button'
import { urlConfig } from 'config/url.config.ts'
import { ThemeSelector } from 'modules/theme/components/theme-selector'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { getSystemTheme } from 'modules/theme/utils/get-system-theme'
import { UserAvatar } from 'modules/user/components/user-avatar'
import { UserRole } from 'modules/user/schemas/user.schema'
import { CreateProjectSheet } from 'pages/create-project-sheet'
import { forwardRef, memo } from 'react'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'

interface NavbarProps {}
export const Navbar = memo(
    forwardRef<React.ComponentRef<'div'>, NavbarProps>(({ ...props }, ref) => {
        const theme = useThemeStore(state => state.theme)
        const isAuth = useIsAuthenticated()()
        const auth = useAuthUser()()

        return (
            <div ref={ref} {...props} className="bg-background/90 fixed z-50 w-full whitespace-nowrap border-b backdrop-blur-sm">
                <div className="container flex w-full items-center justify-between bg-none py-3">
                    <div className="flex items-center gap-2">
                        {/* <VenetianMask /> */}
                        <Link to={isAuth ? urlConfig.pages.dashboard : 'http://paracounter.com/'} className="text-lg font-bold">
                            {/* Visitor Activity */}
                            <NavbarLogo theme={theme} />
                        </Link>
                    </div>

                    {isAuth && (
                        <nav className="flex items-center gap-2">
                            <CommandMenu />
                            {/* dashboard */}
                            <Button asChild variant="ghost" size="sm">
                                <Link to={urlConfig.pages.dashboard}>Dashboard</Link>
                            </Button>
                            {/* logs */}
                            {auth?.role === UserRole.SUPERADMIN && (
                                <Button asChild variant="ghost" size="sm">
                                    <Link to={urlConfig.pages.logs}>Logs</Link>
                                </Button>
                            )}
                            {/* manage users --- SUPERADMIN and Admin */}
                            {(auth?.role === UserRole.SUPERADMIN || auth?.role === UserRole.ADMIN) && (
                                <Button asChild variant="ghost" size="sm">
                                    <Link to={urlConfig.pages.manageUsers}>Manage Users</Link>
                                </Button>
                            )}
                            {/* create project */}
                            <Button variant="ghost" size="sm" asChild>
                                <CreateProjectSheet>Create Project</CreateProjectSheet>
                            </Button>
                            {/* div - fix incorrect theme selector size */}
                            <div>
                                <ThemeSelector />
                            </div>
                            <UserAvatar />
                        </nav>
                    )}
                    {!isAuth && (
                        <Button
                            className={cn(
                                'rounded-xl border-2 text-xl',
                                theme === 'dark'
                                    ? 'border-[#0B1E55] bg-[#030E31] transition-all hover:bg-slate-200 hover:text-slate-900'
                                    : 'bg-slate-100',
                            )}
                            variant="secondary"
                            asChild
                        >
                            <Link to={urlConfig.pages.login}>Login</Link>
                        </Button>
                    )}
                </div>
            </div>
        )
    }),
)
Navbar.displayName = 'Navbar'

const NavbarLogo = ({ theme }: { theme: string }) => {
    const themeColor =
        theme === 'light' ? 'black' : theme === 'system' ? (getSystemTheme() === 'light' ? 'black' : 'white') : 'white'
    return (
        <div className="flex items-center gap-2">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.5" y="1.5" width="27" height="27" rx="3.5" stroke={themeColor} strokeWidth="3" />
                <line x1="1.92099" y1="18.2986" x2="7.07827" y2="12.9581" stroke={themeColor} strokeWidth="3" />
                <line x1="5.04643" y1="12.9253" x2="14.5464" y2="22.1753" stroke={themeColor} strokeWidth="3" />
                <line x1="12.4643" y1="22.165" x2="28.9643" y2="6.41497" stroke={themeColor} strokeWidth="3" />
            </svg>
            <span className="text-2xl font-medium">ParaCounter</span>
        </div>
    )
}
