import { Avatar, AvatarFallback } from 'components/ui/avatar'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { defaultIconProps } from 'config/constants.config'
import { getTimeDifference } from 'config/dayjs.config'
import dayjs from 'dayjs'
import { Map } from 'lucide-react'
import { ReportSchemaType } from 'modules/details/schemas/report.schema'
import { getFallbackLetters } from 'modules/user/utils/get-fallback-letters'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { forwardRef, memo, useEffect, useState } from 'react'

interface DetailsRecentUsersProps extends React.ComponentProps<typeof Card> {
    data: ReportSchemaType[]
}
export const DetailsRecentUsers = memo(
    forwardRef<React.ComponentRef<typeof Card>, DetailsRecentUsersProps>(({ data, ...props }, ref) => {
        const [userIp, setUserIp] = useState<string>('')

        useEffect(() => {
            async function fetchUserIp() {
                const ip = await getUserIp()
                setUserIp(ip)
            }

            fetchUserIp()
        }, [])

        return (
            <Card ref={ref} {...props}>
                <CardHeader>
                    <CardTitle>Recent users</CardTitle>
                    <CardDescription>Who have visited the website</CardDescription>
                </CardHeader>
                <CardContent className="space-y-5">
                    {data
                        // @ts-expect-error new array method
                        .toSorted((a, b) => dayjs(b.date).diff(a.date))
                        .slice(0, 6)
                        .map((report: ReportSchemaType) => {
                            return (
                                <div key={report.id} className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <Avatar>
                                            <AvatarFallback>{getFallbackLetters(report.ip ?? '0.0.0.0', '.')}</AvatarFallback>
                                        </Avatar>
                                        <div className="space-y-1">
                                            <p className="text-sm leading-none">{report.ip}</p>
                                            <p className="text-muted-foreground text-sm">{getTimeDifference(report.date)}</p>
                                        </div>
                                        {report.ip === userIp && (
                                            <div className="space-y-1">
                                                <Badge className="text-sm leading-none">You</Badge>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span className="leading-none">
                                            {report.city}, {report.country}
                                        </span>
                                        <Button asChild variant="outline" size="icon">
                                            <a
                                                href={`https://www.google.com/maps/place/${report.lat},${report.lng}`}
                                                target="_blank"
                                            >
                                                <Map {...defaultIconProps} />
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                </CardContent>
            </Card>
        )
    }),
)
DetailsRecentUsers.displayName = 'DetailsRecentUsers'
