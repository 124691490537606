import { ColumnDef } from '@tanstack/react-table'
import { Button } from 'components/ui/button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { urlConfig } from 'config/url.config'
import dayjs from 'dayjs'
import { DashboardRowActionsCell } from 'modules/dashboard/cells/dashboard-data-table-row-actions-cell'
import { DashboardDetailsCell } from 'modules/dashboard/cells/dashboard-details-cell'
import { DashboardSnippetsCell } from 'modules/dashboard/cells/dashboard-snippets-cell'
import { DashboardStatusCell } from 'modules/dashboard/cells/dashboard-status-cell'
import { ProjectSchemaType } from 'modules/project/schemas/project.schema'
import { Link } from 'react-router-dom'
import { Area, AreaChart } from 'recharts'

export const dashboardColumns: ColumnDef<ProjectSchemaType>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => (
            <span onClick={() => row.original.navigateFn?.(`${urlConfig.pages.details}/${row.getValue('id')}`)}>
                {row.getValue('name')}
            </span>
        ),
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'url',
        header: ({ column }) => <DataTableColumnHeader column={column} title="URL" />,
        cell: ({ row }) => {
            const url = row.getValue('url') as ProjectSchemaType['url']
            return (
                <Button variant="link" asChild className="font-normal">
                    <Link to={url} target="_blank">
                        {url}
                    </Link>
                </Button>
            )
        },
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'monthStats',
        header: 'Traffic',
        cell: ({ row }) => (
            <AreaChart
                onClick={() => row.original.navigateFn?.(`${urlConfig.pages.details}/${row.getValue('id')}`)}
                width={100}
                height={50}
                data={row.getValue('monthStats')}
                syncId="anyId"
            >
                <Area type="monotone" dataKey="value" stroke="#7e22ce" fill="#7e22ce" />
            </AreaChart>
        ),
    },
    {
        accessorKey: 'today',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Today" />,
        cell: ({ row }) => {
            const today = row.getValue('today')
            return (
                <div
                    onClick={() => row.original.navigateFn?.(`${urlConfig.pages.details}/${row.getValue('id')}`)}
                    className="w-full text-center"
                >
                    {today?.toString()}
                </div>
            )
        },
        sortingFn: 'alphanumeric',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'yesterday',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Yesterday" />,
        cell: ({ row }) => {
            const yesterday = row.getValue('yesterday')
            return (
                <div
                    onClick={() => row.original.navigateFn?.(`${urlConfig.pages.details}/${row.getValue('id')}`)}
                    className="w-full text-center"
                >
                    {yesterday?.toString()}
                </div>
            )
        },
        sortingFn: 'alphanumeric',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'thisMonth',
        header: ({ column }) => <DataTableColumnHeader column={column} title={dayjs().format('MMM, YYYY')} />,
        cell: ({ row }) => {
            const thisMonth = row.getValue('thisMonth')
            return (
                <div
                    onClick={() => row.original.navigateFn?.(`${urlConfig.pages.details}/${row.getValue('id')}`)}
                    className="w-full text-center"
                >
                    {thisMonth?.toString()}
                </div>
            )
        },
        sortingFn: 'alphanumeric',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => (
            <DashboardStatusCell
                id={row.getValue('id')}
                name={row.getValue('name')}
                status={row.getValue('status')}
                className="w-full max-w-[150px]"
            />
        ),
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
    {
        header: 'Details',
        cell: ({ row }) => <DashboardDetailsCell id={row.getValue('id')} status={row.getValue('status')} />,
    },
    {
        header: 'Snippets',
        cell: ({ row }) => (
            <DashboardSnippetsCell id={row.getValue('id')} name={row.getValue('name')} className="w-full max-w-[150px]" />
        ),
    },
    {
        header: 'More',
        cell: ({ row }) => <DashboardRowActionsCell id={row.getValue('id')} name={row.getValue('name')} />,
    },
]
