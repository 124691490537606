import { ENV, dotenv } from 'config/dotenv.config'
import { HtmlSnippet } from 'modules/project/components/html-snippet'
import { memo, useMemo } from 'react'
import { cn } from 'utils/cn'

interface ProjectCodeSnippetsProps extends React.ComponentProps<'div'> {
    projectId: string
    blockSnippets: boolean
    children?: React.ReactNode
}
export const ProjectCodeSnippets: React.FC<ProjectCodeSnippetsProps> = memo(
    ({ projectId, blockSnippets, className, children, ...props }) => {
        const url = useMemo(() => {
            const url = new URL(`${dotenv.get(ENV.API_URL)}/script`)
            url.searchParams.set('projectId', projectId)
            return url.toString()
        }, [projectId])

        return (
            <div
                {...props}
                className={cn(
                    'flex flex-col gap-6 transition-all duration-300',
                    blockSnippets && 'pointer-events-none select-none opacity-50',
                    className,
                )}
            >
                <HtmlSnippet url={url} />
                {/* <ReactSnippet url={url} /> */}
                {/* <AngularSnippet url={url} /> */}
                {/* <VueSnippet url={url} /> */}
                {children}
            </div>
        )
    },
)
ProjectCodeSnippets.displayName = 'ProjectCodeSnippets'
