import { forwardRef, memo } from 'react'
import { Bar, BarChart as RechartsBarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

export type ChartDataType = Array<{
    name: string
    value: number
}>

interface BarChartProps extends Omit<React.ComponentProps<typeof ResponsiveContainer>, 'children'> {
    data: ChartDataType
}
export const BarChart = memo(
    forwardRef<React.ComponentRef<typeof ResponsiveContainer>, BarChartProps>(({ data, ...props }, ref) => {
        return (
            <ResponsiveContainer ref={ref} {...props}>
                <RechartsBarChart data={data} className='overflow-x-auto'>
                    <XAxis dataKey="name" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                    <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                    <Bar dataKey="value" radius={4} className="fill-primary" />
                </RechartsBarChart>
            </ResponsiveContainer>
        )
    }),
)
BarChart.displayName = 'Chart'
