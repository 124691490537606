import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { ProjectSchemaType } from 'modules/project/schemas/project.schema'

export const projectsApi = createApi({
    reducerPath: 'projectApi',
    tagTypes: ['Project'],
    keepUnusedDataFor: 0,
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllProjects: builder.query<ProjectSchemaType[], void>({
            query: () => ({
                url: '/project',
                method: 'GET',
            }),
            providesTags: ['Project'],
        }),
        getAllProjectsByOwnerId: builder.query<ProjectSchemaType[], ProjectSchemaType['ownerId']>({
            query: id => ({
                url: `/project/owner/${id}`,
                method: 'GET',
            }),
            providesTags: ['Project'],
        }),
        getProject: builder.query<ProjectSchemaType, ProjectSchemaType['id']>({
            query: id => ({
                url: `/project/${id}`,
                method: 'GET',
            }),
            providesTags: ['Project'],
        }),
        createProject: builder.mutation<ProjectSchemaType, Omit<ProjectSchemaType, 'id'>>({
            query: project => ({
                url: '/project',
                method: 'POST',
                body: project,
            }),
            invalidatesTags: ['Project'],
        }),
        updateProject: builder.mutation<void, Partial<ProjectSchemaType> & Pick<ProjectSchemaType, 'id'>>({
            query: project => ({
                url: `/project`,
                method: 'PUT',
                body: project,
            }),
            invalidatesTags: ['Project'],
        }),
        deleteProject: builder.mutation<void, ProjectSchemaType['id']>({
            query: id => ({
                url: `/project/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Project'],
        }),
    }),
})

export const {
    useGetAllProjectsQuery,
    useGetAllProjectsByOwnerIdQuery,
    useGetProjectQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = projectsApi
