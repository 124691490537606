/* eslint-disable @typescript-eslint/no-explicit-any */
import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { urlConfig } from 'config/url.config'
import { DashboardCards } from 'modules/dashboard/components/dashboard-cards'
import { dashboardColumns } from 'modules/dashboard/components/dashboard-columns'
import { ProjectStatus } from 'modules/project/schemas/project.schema'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { memo } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { useGetAllProjectsByOwnerIdQuery } from 'redux-store/api/project-api'
import { cn } from 'utils/cn'
import CreateFirstProject from './create-first-project'

interface DashboardPageProps {}
export const DashboardPage: React.FC<DashboardPageProps> = memo(() => {
    const theme = useThemeStore(state => state.theme)
    const auth = useAuthUser()()
    const { data: ownerProjects, isLoading: isLoadingOwnerProjects } = useGetAllProjectsByOwnerIdQuery(
        auth?.userOwnerId === 'owner' ? auth?.id : auth?.userOwnerId,
    )

    const navigate = useNavigate()

    if (isLoadingOwnerProjects) {
        return <FullPageLoader />
    }

    return (
        <div className=" container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <Heading title="Dashboard" description="Manage your projects" />
            <DashboardCards data={ownerProjects ?? []} />
            {(ownerProjects?.length ?? 0) > 0 ? (
                <DataTable
                    columns={dashboardColumns}
                    data={ownerProjects?.map(project => ({ ...project, navigateFn: (url: string) => navigate(url) })) ?? []}
                    pagination={!!((ownerProjects?.length ?? 0) > 5)}
                    initialSorting={{
                        column: 'today',
                        direction: 'desc',
                    }}
                    initialHidden={['id']}
                    className="overflow-x-auto"
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="url" label="URL" />
                        <DataTableFacetedFilter
                            column="status"
                            options={Object.values(ProjectStatus).map(status => ({ data: status }))}
                        />
                    </DataTableToolbar>
                </DataTable>
            ) : (
                <div className="flex items-center justify-center">
                    <div
                        className={cn(
                            'mt-5 flex flex-col gap-3 rounded-xl border-4 p-5 lg:w-1/3',
                            theme === 'light' ? 'border-zinc-50 bg-white' : '',
                        )}
                    >
                        <h2 className="text-2xl font-semibold">Create your first project</h2>
                        <span>Add your first project</span>
                        <CreateFirstProject />
                    </div>
                </div>
            )}
            <div className="h-5" />
        </div>
    )
})
DashboardPage.displayName = 'DashboardPage'
