import { InlineCode } from 'components/ui/code'
import { toast } from 'components/ui/use-toast'
import { ProjectStatus } from 'modules/project/schemas/project.schema'

export const toastConfig = {
    login: {
        success: (name: string) =>
            toast({
                title: 'Login successful',
                description: (
                    <>
                        Welcome back, <InlineCode>{name}</InlineCode>
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Failed to login',
                description: 'Please try again later',
                variant: 'destructive',
            }),
        successWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Check your email <InlineCode>{email}</InlineCode> to confirm it. If you don't see the letter, check the
                        spam folder
                    </>
                ),
            }),
    },

    register: {
        success: (name: string) =>
            toast({
                title: 'Register successful',
                description: (
                    <>
                        Welcome, <InlineCode>{name}</InlineCode>
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Failed to register',
                description: 'Please try again later',
                variant: 'destructive',
            }),
    },

    reset: {
        resetWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Please check your email <InlineCode>{email}</InlineCode>, we have sent you further instructions.
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Failed to reset',
                description: 'Please try again later',
                variant: 'destructive',
            }),
    },

    //

    project: {
        create: {
            success: (name: string) =>
                toast({
                    title: 'Project created',
                    description: (
                        <>
                            Project <InlineCode>{name}</InlineCode> created successfully. Click on project ID to view the snippets
                            and paste them to your website source code
                        </>
                    ),
                }),
            error: (name: string) =>
                toast({
                    title: 'Failed to create project',
                    description: (
                        <>
                            Error occured while creating project <InlineCode>{name}</InlineCode> Please try again later
                        </>
                    ),
                    variant: 'destructive',
                }),
        },
        update: {
            success: (name: string, status: ProjectStatus) =>
                toast({
                    title: 'Project updated',
                    description: (
                        <>
                            Project "{name}" is {status === ProjectStatus.ACTIVE ? ProjectStatus.DISABLED : ProjectStatus.ACTIVE}{' '}
                            now
                        </>
                    ),
                }),
            error: (name: string) =>
                toast({
                    title: 'Failed to update project',
                    description: (
                        <>
                            Error occured while updating <InlineCode>{name}</InlineCode>. Please try again later
                        </>
                    ),
                    variant: 'destructive',
                }),
            pending: (name: string) =>
                toast({
                    title: 'Project is pending',
                    description: (
                        <>
                            Project <InlineCode>{name}</InlineCode> is pending. Please wait for the first report. And don't forget
                            to add code snippet to your website's source code
                        </>
                    ),
                    variant: 'destructive',
                }),
        },
        delete: {
            success: (name: string) =>
                toast({
                    title: 'Project deleted',
                    description: (
                        <>
                            Project <InlineCode>{name}</InlineCode> deleted successfully
                        </>
                    ),
                    variant: 'destructive',
                }),
            error: (name: string) =>
                toast({
                    title: 'Failed to delete project',
                    description: (
                        <>
                            Error occured while updating <InlineCode>{name}</InlineCode>. Please try again later
                        </>
                    ),
                    variant: 'destructive',
                }),
        },
    },

    //

    user: {
        create: {
            success: (email: string) =>
                toast({
                    title: 'User created',
                    description: (
                        <>
                            User with email <InlineCode>{email}</InlineCode> has been created
                        </>
                    ),
                }),
            error: () =>
                toast({
                    title: 'Failed to create user',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },
}
