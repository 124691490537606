import { SlotItemWithIcon } from 'components/slot-item-with-icon'
import { Button } from 'components/ui/button'
import {
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from 'components/ui/command'
import { urlConfig } from 'config/url.config'
import { BoxSelect, Braces, ChevronLeft, ChevronRight, FolderPlus, LayoutDashboard, LogIn, LogOut, Users } from 'lucide-react'
import { ProjectStatus } from 'modules/project/schemas/project.schema'
import { useProjectStore } from 'modules/project/store/project.store'
import { ThemesIcons } from 'modules/theme/constants/theme.constant'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import { useIsAuthenticated, useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { useGetAllProjectsQuery } from 'redux-store/api/project-api'
import { cn } from 'utils/cn'
import { paramsToString } from 'utils/params-to-string'
import { substringSearch } from 'utils/substring-search'

interface CommandMenuProps extends React.ComponentProps<typeof Button> {}
export const CommandMenu = memo(
    forwardRef<React.ComponentRef<typeof Button>, CommandMenuProps>(({ className, ...props }, ref) => {
        const [open, setOpen] = useState<boolean>(false)

        // project
        const { data: projects, isLoading: projectsLoading, isError: projectsError } = useGetAllProjectsQuery()
        const setCreateProjectOpen = useProjectStore(state => state.setCreateProjectOpen)
        // themes
        const theme = useThemeStore(state => state.theme)
        const setTheme = useThemeStore(state => state.setTheme)
        // user
        const isAuth = useIsAuthenticated()()
        const signOut = useSignOut()
        // router
        const navigate = useNavigate()

        const runCommand = useCallback((command: () => unknown) => {
            setOpen(false)
            command()
        }, [])

        useEffect(() => {
            const down = (e: KeyboardEvent) => {
                if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                    e.preventDefault()
                    setOpen(prev => !prev)
                }
            }
            document.addEventListener('keydown', down)
            return () => {
                document.removeEventListener('keydown', down)
            }
        }, [])

        return (
            <>
                <Button
                    ref={ref}
                    variant="outline"
                    onClick={() => setOpen(true)}
                    {...props}
                    className={cn('text-muted-foreground relative w-full justify-start text-sm', className)}
                >
                    <span className="sm:pr-10">Search anything...</span>
                    <kbd className="bg-muted pointer-events-none absolute right-3 my-auto hidden h-5 select-none items-center gap-1 rounded border px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
                        <span className="text-xs">⌘</span>K
                    </kbd>
                </Button>
                <CommandDialog
                    filter={(value, search) => (substringSearch(value, search) ? 1 : 0)}
                    open={open}
                    onOpenChange={setOpen}
                >
                    <CommandInput placeholder="Type a command to search..." />
                    <CommandList className="py-1">
                        <CommandEmpty>No results found.</CommandEmpty>

                        {/* quick actions */}
                        <CommandGroup heading="Quick actions">
                            <CommandItem
                                value={paramsToString('go', 'back', 'quick action')}
                                onSelect={() => runCommand(() => navigate(-1))}
                            >
                                <SlotItemWithIcon Icon={ChevronLeft}>Back</SlotItemWithIcon>
                            </CommandItem>
                            <CommandItem
                                value={paramsToString('go', 'forward', 'quick action')}
                                onSelect={() => runCommand(() => navigate(1))}
                            >
                                <SlotItemWithIcon Icon={ChevronRight}>Forward</SlotItemWithIcon>
                            </CommandItem>
                        </CommandGroup>
                        <CommandSeparator />

                        {/* pages */}
                        {isAuth && (
                            <CommandGroup heading="Pages">
                                <CommandItem
                                    onSelect={() =>
                                        runCommand(() => {
                                            navigate(urlConfig.pages.dashboard)
                                        })
                                    }
                                >
                                    <SlotItemWithIcon Icon={LayoutDashboard}>Dashboard</SlotItemWithIcon>
                                </CommandItem>
                                <CommandItem onSelect={() => runCommand(() => setCreateProjectOpen(true))}>
                                    <SlotItemWithIcon Icon={FolderPlus}>Create Project</SlotItemWithIcon>
                                </CommandItem>
                                <CommandItem onSelect={() => runCommand(() => navigate(urlConfig.pages.manageUsers))}>
                                    <SlotItemWithIcon Icon={Users}>Manage Users</SlotItemWithIcon>
                                </CommandItem>
                                <CommandItem onSelect={() => runCommand(() => navigate(urlConfig.pages.logs))}>
                                    <SlotItemWithIcon Icon={Braces}>Logs</SlotItemWithIcon>
                                </CommandItem>
                            </CommandGroup>
                        )}

                        {/* projects */}
                        {isAuth && (
                            <CommandGroup heading="Projects">
                                {projectsLoading && <CommandItem>Loading projects...</CommandItem>}
                                {projectsError && <CommandItem>Error loading projects</CommandItem>}
                                {!projectsLoading &&
                                    !projectsError &&
                                    projects &&
                                    projects
                                        .filter(project => project.status !== ProjectStatus.PENDING)
                                        .map(project => (
                                            <CommandItem
                                                key={project.id}
                                                onSelect={() =>
                                                    runCommand(() =>
                                                        navigate(urlConfig.pages.detailsId.replace(':id', project.id)),
                                                    )
                                                }
                                            >
                                                <SlotItemWithIcon Icon={BoxSelect}>{project.name}</SlotItemWithIcon>
                                            </CommandItem>
                                        ))}
                            </CommandGroup>
                        )}

                        {/* themes */}
                        <CommandGroup heading="Themes">
                            {Object.entries(ThemesIcons).map(([key, Value]) => (
                                <CommandItem
                                    key={key}
                                    value={paramsToString('theme', 'change', key)}
                                    onSelect={() => runCommand(() => setTheme(key as keyof typeof ThemesIcons))}
                                    className="group"
                                >
                                    <SlotItemWithIcon Icon={Value}>
                                        <span className="capitalize">{key}</span>
                                        {theme.toLowerCase() === key.toLowerCase() && (
                                            <>
                                                <span className="text-border group-hover:text-foreground/50 px-2 transition-colors duration-300">
                                                    |
                                                </span>
                                                <span className="text-muted-foreground">Active</span>
                                            </>
                                        )}
                                    </SlotItemWithIcon>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                        <CommandSeparator />

                        {/* users */}
                        <CommandGroup heading="User">
                            {isAuth ? (
                                <CommandItem
                                    value={paramsToString('user', 'logout', 'sign out')}
                                    onSelect={() => runCommand(() => signOut())}
                                    className="group"
                                >
                                    <SlotItemWithIcon Icon={LogOut}>Sign out</SlotItemWithIcon>
                                </CommandItem>
                            ) : (
                                <CommandItem
                                    value={paramsToString('user', 'login', 'sign in')}
                                    onSelect={() => runCommand(() => navigate(urlConfig.pages.login))}
                                >
                                    <SlotItemWithIcon Icon={LogIn}>Sign in</SlotItemWithIcon>
                                </CommandItem>
                            )}
                        </CommandGroup>
                    </CommandList>
                </CommandDialog>
            </>
        )
    }),
)
CommandMenu.displayName = 'CommandMenu'
