import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { InlineCode } from 'components/ui/code'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { defaultIconProps } from 'config/constants.config'
import { toastConfig } from 'config/toast.config'
import { MoreHorizontal } from 'lucide-react'
import { ProjectSchemaType } from 'modules/project/schemas/project.schema'
import { forwardRef, memo, useCallback } from 'react'
import { useDeleteProjectMutation } from 'redux-store/api/project-api'

interface DashboardRowActionsCellProps extends React.ComponentProps<typeof DropdownMenuTrigger> {
    id: ProjectSchemaType['id']
    name: ProjectSchemaType['name']
}
export const DashboardRowActionsCell = memo(
    forwardRef<React.ComponentRef<typeof DropdownMenuTrigger>, DashboardRowActionsCellProps>(({ id, name, ...props }, ref) => {
        const [deleteProject, deleteProjectRes] = useDeleteProjectMutation()

        const onDelete = useCallback<() => void>(() => {
            try {
                deleteProject(id)
                if (deleteProjectRes.isError) {
                    console.error(deleteProjectRes.error)
                    throw new Error()
                }
                toastConfig.project.delete.success(name)
            } catch (error) {
                toastConfig.project.delete.error(name)
            }
        }, [deleteProject, deleteProjectRes.error, deleteProjectRes.isError, id, name])

        return (
            <DropdownMenu>
                <DropdownMenuTrigger ref={ref} asChild {...props}>
                    <Button variant="ghost" size="icon" className="data-[state=open]:bg-muted flex h-8 w-8 p-0">
                        <MoreHorizontal {...defaultIconProps} className="h-4 w-4" />
                        <span className="sr-only">Open more</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[160px]">
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button variant="destructive" className="w-full">
                                Delete
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete project{' '}
                                    {<InlineCode>{name}</InlineCode>} from our servers.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                    className="bg-destructive hover:bg-destructive/80 text-white"
                                    onClick={onDelete}
                                >
                                    Delete
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }),
)
DashboardRowActionsCell.displayName = 'DashboardRowActionsCell'
