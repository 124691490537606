import { urlConfig } from 'config/url.config'
import { RequireAuth } from 'modules/user/hoc/require-auth'
import { ThrowAuth } from 'modules/user/hoc/throw-auth'
import { UserRole } from 'modules/user/schemas/user.schema'
import { DashboardPage } from 'pages/dashboard-page'
import { DetailsPage } from 'pages/details-page'
import Login from 'pages/login'
import { LoginPage } from 'pages/login-page'
import { LogsPage } from 'pages/logs-page'
import { ManageUsersPage } from 'pages/manage-users-page'
import { NotFoundPage } from 'pages/not-found-page'
import Register from 'pages/register'
import ResetPassword from 'pages/reset-password'
import { memo } from 'react'
import { Outlet, useRoutes } from 'react-router-dom'

interface RoutesProps {}
export const Routes: React.FC<RoutesProps> = memo(() => {
    return useRoutes([
        // auth required
        {
            path: urlConfig.pages.main,
            element: (
                <RequireAuth>
                    <Outlet />
                </RequireAuth>
            ),
            children: [
                {
                    path: urlConfig.pages.dashboard,
                    element: <DashboardPage />,
                },
                {
                    path: urlConfig.pages.detailsId,
                    element: <DetailsPage />,
                },
                {
                    path: urlConfig.pages.logs,
                    element: (
                        <RequireAuth allowedRoles={[UserRole.SUPERADMIN]}>
                            <LogsPage />,
                        </RequireAuth>
                    ),
                },
                {
                    path: urlConfig.pages.manageUsers,
                    element: (
                        <RequireAuth allowedRoles={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
                            <ManageUsersPage />
                        </RequireAuth>
                    ),
                },
            ],
        },
        // no auth required
        {
            path: urlConfig.pages['all-login'],
            element: (
                <ThrowAuth callbackPath={urlConfig.pages.main}>
                    <LoginPage />
                </ThrowAuth>
            ),
        },
        {
            path: urlConfig.pages.login,
            element: (
                <ThrowAuth callbackPath={urlConfig.pages.main}>
                    <Login />
                </ThrowAuth>
            ),
        },
        {
            path: urlConfig.pages.register,
            element: (
                <ThrowAuth callbackPath={urlConfig.pages.main}>
                    <Register />
                </ThrowAuth>
            ),
        },
        {
            path: urlConfig.pages.reset,
            element: (
                <ThrowAuth callbackPath={urlConfig.pages.main}>
                    <ResetPassword />
                </ThrowAuth>
            ),
        },
        //
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ])
})
Routes.displayName = 'Routes'
