import { Navbar } from 'components/Navbar'
import { TailwindIndicator } from 'components/tailwind-indicator'
import { Toaster } from 'components/ui/toaster'
import { SnippetsModal } from 'modules/project/components/snippets-modal'
import { ThemeUpdater } from 'modules/theme/components/theme-updater'
import { ErrorPage } from 'pages/error-page'
import { memo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes } from 'routes'
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'utils/NODE_ENV'

export const App: React.FC = memo(() => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Navbar />
            <Routes />
            {/*  */}
            <SnippetsModal />
            {/* TODO ^ refactor */}
            <Toaster />
            <ThemeUpdater />
            <TailwindIndicator show={NODE_ENV_DEV && !NODE_ENV_PROD} />
            {/* service chat test integration */}
        </ErrorBoundary>
    )
})
App.displayName = 'App'
